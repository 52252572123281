import React, { useState } from 'react';
import Business1 from 'assets/img/Business1.mp4';
import Business2 from 'assets/img/Business2.mp4';
import Business3 from 'assets/img/Business3.mp4';
import Business4 from 'assets/img/Business4.mp4';
import Business5 from 'assets/img/Business5.mp4';
import Business6 from 'assets/img/Business6.mp4';
import Business1EN from 'assets/img/Business1EN.mp4';
import Business2EN from 'assets/img/Business2EN.mp4';
import Business3EN from 'assets/img/Business3EN.mp4';
import Business4EN from 'assets/img/Business4EN.mp4';
import Business5EN from 'assets/img/Business5EN.mp4';
import Business6EN from 'assets/img/Business6EN.mp4';
import Investor1 from 'assets/img/Investor1.mp4';
import Investor2 from 'assets/img/Investor2.mp4';
import Investor3 from 'assets/img/Investor3.mp4';
import Investor4 from 'assets/img/Investor4.mp4';
import Investor5 from 'assets/img/Investor5.mp4';
import Investor6 from 'assets/img/Investor6.mp4';
import Investor1EN from 'assets/img/Investor1EN.mp4';
import Investor2EN from 'assets/img/Investor2EN.mp4';
import Investor3EN from 'assets/img/Investor3EN.mp4';
import Investor4EN from 'assets/img/Investor4EN.mp4';
import Investor5EN from 'assets/img/Investor5EN.mp4';
import Investor6EN from 'assets/img/Investor6EN.mp4';
import Broker1 from 'assets/img/Broker1.mp4';
import Broker2 from 'assets/img/Broker2.mp4';
import Broker3 from 'assets/img/Broker3.mp4';
import Broker4 from 'assets/img/Broker4.mp4';
import Broker5 from 'assets/img/Broker5.mp4';
import Broker6 from 'assets/img/Broker6.mp4';
import Broker1EN from 'assets/img/Broker1EN.mp4';
import Broker2EN from 'assets/img/Broker2EN.mp4';
import Broker3EN from 'assets/img/Broker3EN.mp4';
import Broker4EN from 'assets/img/Broker4EN.mp4';
import Broker5EN from 'assets/img/Broker5EN.mp4';
import Broker6EN from 'assets/img/Broker6EN.mp4';
import Container from 'index.style';
import { Button } from 'components/common/Button';
import {
  BottomButtons,
  ContainerBox,
  LeftSide,
  LeftSideTitle,
  RightSide,
  StepArrow,
  StepDescriptionText,
  StepHeader,
  StepsContainer,
  StepWrapper,
  StyledVideo,
  Tab,
  TabKey,
  Tabs,
  ToggleBroker,
  ToggleBusiness,
  ToggleInvestor,
  VideoWrapper,
} from './VideoBlock.style';
import { useTranslation } from 'react-i18next';
import { useModal } from 'context/ModalContext';
import { refURL } from 'URL';

type Step = {
  title: string;
  video: string;
  description: string;
};

type TabsData = Record<string, { steps: Step[] }>;

export const VideoBlock: React.FC = () => {
  const { openModal } = useModal();

  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language; // Получаем текущий язык

  const [activeTab, setActiveTab] = useState<string>('investorTab'); // Фиксированный ключ
  const [activeStep, setActiveStep] = useState(0);

  const isEnglish = currentLanguage === 'en'; // Проверка на английский язык

  const getLocalizedVideo = (local: string, en: string) => (isEnglish ? en : local);

  const iconMap: Record<string, JSX.Element> = {
    businessTab: <ToggleBusiness />,
    investorTab: <ToggleInvestor />,
    brokersFundsTab: <ToggleBroker />,
  };

  const tabNames: Record<string, string> = {
    businessTab: t('tabsData.businessTab'),
    investorTab: t('tabsData.investorTab'),
    brokersFundsTab: t('tabsData.brokersFundsTab'),
  };

  const data: TabsData = {
    businessTab: {
      steps: [
        {
          title: t('tabsData.business.step1.title'),
          video: getLocalizedVideo(Business5, Business5EN),
          description: t('tabsData.business.step1.description'),
        },
        {
          title: t('tabsData.business.step2.title'),
          video: getLocalizedVideo(Business6, Business6EN),
          description: t('tabsData.business.step2.description'),
        },
        {
          title: t('tabsData.business.step3.title'),
          video: getLocalizedVideo(Business4, Business4EN),
          description: t('tabsData.business.step3.description'),
        },
        {
          title: t('tabsData.business.step4.title'),
          video: getLocalizedVideo(Business1, Business1EN),
          description: t('tabsData.business.step4.description'),
        },
        {
          title: t('tabsData.business.step5.title'),
          video: getLocalizedVideo(Business3, Business3EN),
          description: t('tabsData.business.step5.description'),
        },
        {
          title: t('tabsData.business.step6.title'),
          video: getLocalizedVideo(Business2, Business2EN),
          description: t('tabsData.business.step6.description'),
        },
      ],
    },
    investorTab: {
      steps: [
        {
          title: t('tabsData.investor.step1.title'),
          video: getLocalizedVideo(Investor6, Investor6EN),
          description: t('tabsData.investor.step1.description'),
        },
        {
          title: t('tabsData.investor.step2.title'),
          video: getLocalizedVideo(Investor2, Investor2EN),
          description: t('tabsData.investor.step2.description'),
        },
        {
          title: t('tabsData.investor.step3.title'),
          video: getLocalizedVideo(Investor1, Investor1EN),
          description: t('tabsData.investor.step3.description'),
        },
        {
          title: t('tabsData.investor.step4.title'),
          video: getLocalizedVideo(Investor5, Investor5EN),
          description: t('tabsData.investor.step4.description'),
        },
        {
          title: t('tabsData.investor.step5.title'),
          video: getLocalizedVideo(Investor3, Investor3EN),
          description: t('tabsData.investor.step5.description'),
        },
        {
          title: t('tabsData.investor.step6.title'),
          video: getLocalizedVideo(Investor4, Investor4EN),
          description: t('tabsData.investor.step6.description'),
        },
      ],
    },
    brokersFundsTab: {
      steps: [
        {
          title: t('tabsData.brokersFunds.step1.title'),
          video: getLocalizedVideo(Broker1, Broker1EN),
          description: t('tabsData.brokersFunds.step1.description'),
        },
        {
          title: t('tabsData.brokersFunds.step2.title'),
          video: getLocalizedVideo(Broker4, Broker4EN),
          description: t('tabsData.brokersFunds.step2.description'),
        },
        {
          title: t('tabsData.brokersFunds.step3.title'),
          video: getLocalizedVideo(Broker3, Broker3EN),
          description: t('tabsData.brokersFunds.step3.description'),
        },
        {
          title: t('tabsData.brokersFunds.step4.title'),
          video: getLocalizedVideo(Broker6, Broker6EN),
          description: t('tabsData.brokersFunds.step4.description'),
        },
        {
          title: t('tabsData.brokersFunds.step5.title'),
          video: getLocalizedVideo(Broker2, Broker2EN),
          description: t('tabsData.brokersFunds.step5.description'),
        },
        {
          title: t('tabsData.brokersFunds.step6.title'),
          video: getLocalizedVideo(Broker5, Broker5EN),
          description: t('tabsData.brokersFunds.step6.description'),
        },
      ],
    },
  };

  const currentData = data[activeTab];
  const currentStep = currentData.steps[activeStep];

  return (
    <Container>
      <ContainerBox>
        <LeftSide>
          <LeftSideTitle>{t('tabsData.header')}</LeftSideTitle>
          <Tabs>
            {Object.keys(data).map((tabKey) => {
              const Icon = iconMap[tabKey];
              return (
                <Tab
                  key={tabKey}
                  active={activeTab === tabKey}
                  onClick={() => {
                    setActiveTab(tabKey);
                    setActiveStep(0);
                  }}>
                  {React.cloneElement(Icon, { active: activeTab === tabKey })}
                  {tabNames[tabKey]} {/* Локализованное название */}
                </Tab>
              );
            })}
          </Tabs>

          <StepsContainer>
            {currentData.steps.map((step: Step, index: number) => {
              const isActive = activeStep === index;
              return (
                <StepWrapper key={index}>
                  <StepHeader active={isActive} onClick={() => setActiveStep(index)}>
                    <div style={{ flex: '1', paddingLeft: '8px' }}>{step.title}</div>
                    <StepArrow active={isActive} />
                  </StepHeader>
                  {isActive && step.description && (
                    <StepDescriptionText>{step.description}</StepDescriptionText>
                  )}
                </StepWrapper>
              );
            })}
          </StepsContainer>
          <BottomButtons>
            <a href={refURL} rel='noopener noreferrer'>
              <Button text={t('tabsData.button')} />
            </a>
            <Button text={t('BusinessScreen.secondButtonText')} onClick={() => openModal(2)} />
          </BottomButtons>
        </LeftSide>
        <RightSide>
          <VideoWrapper>
            <StyledVideo src={currentStep.video} controls />
          </VideoWrapper>
        </RightSide>
      </ContainerBox>
    </Container>
  );
};
