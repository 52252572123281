import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector'; // Импорт LanguageDetector
import enTranslations from './locales/en/translation.json';
import ruTranslations from './locales/ru/translation.json';
import kkTranslations from './locales/kz/translation.json';

const savedLanguage = localStorage.getItem('language') || 'kk'; // Сохранённый язык или "ru" по умолчанию

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: enTranslations },
      kk: { translation: kkTranslations },
      ru: { translation: ruTranslations },
    },
    lng: savedLanguage, // Устанавливаем язык из localStorage
    fallbackLng: 'kk', // Резервный язык
    interpolation: { escapeValue: false }, // React уже экранирует строки
    detection: {
      order: ['localStorage', 'navigator'], // Определяем язык через localStorage или настройки браузера
      caches: ['localStorage'], // Кэшируем язык в localStorage
    },
  });

export default i18n;
